module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header>\r\n    <div class="container">\r\n        <a class="logo" href="index.html">\r\n            <img src="' +
((__t = ( require('./images/logo.png') )) == null ? '' : __t) +
'" alt="">\r\n        </a>\r\n        <div class="right">\r\n            <div class="nav">\r\n                <a href="index.html"><span>首页</span></a>\r\n                <a href="product.html"><span>产品</span></a>\r\n                <a href=".html"><span>应用</span></a>\r\n                <a href="about.html"><span>关于我们</span></a>\r\n                <a href="contact.html"><span>联系</span></a>\r\n            </div>\r\n            <div class="search">\r\n                <img src="' +
((__t = ( require('./images/header-search.png') )) == null ? '' : __t) +
'" alt="">\r\n            </div>\r\n            <div class="language">\r\n                <a href="javascript:;" class="active">CN</a>\r\n                <span></span>\r\n                <a href="javascript:;">EN</a>\r\n            </div>\r\n            <div class="open-menu" data-open="cmenu">\r\n                <div class="hamburger hamburger-11">\r\n                    <div></div>\r\n                </div>\r\n            </div>\r\n        </div>\r\n        <div class="mob-nav">\r\n            <ul>\r\n                <li class="nLi">\r\n                    <h3 class="nav-title">\r\n                        <a href="index.html">首页</a>\r\n                    </h3>\r\n                </li>\r\n                <li class="nLi">\r\n                    <h3 class="nav-title">\r\n                        <a href="product.html">产品</a>\r\n                    </h3>\r\n                </li>\r\n                <li class="nLi">\r\n                    <h3 class="nav-title">\r\n                        <a href="product.html">应用</a>\r\n                    </h3>\r\n                </li>\r\n                <li class="nLi">\r\n                    <h3 class="nav-title">\r\n                        <a href="about.html">关于我们</a>\r\n                    </h3>\r\n                </li>\r\n                <li class="nLi">\r\n                    <h3 class="nav-title">\r\n                        <a href="contact.html">联系</a>\r\n                    </h3>\r\n                </li>\r\n            </ul>\r\n        </div>\r\n    </div>\r\n</header>\r\n<div class="search-xl">\r\n    <div class="content">\r\n        <form>\r\n            <input type="text" placeholder="请输入关键词...">\r\n            <a href="javascript:;">\r\n                <img src="' +
((__t = ( require('./images/header-search1.png') )) == null ? '' : __t) +
'" alt="">\r\n            </a>\r\n        </form>\r\n\r\n    </div>\r\n</div>';

}
return __p
}