module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer>\r\n    <div class="container">\r\n        <div class="title">环境、健康、安全资源   联系卓尤</div>\r\n        <ul>\r\n            <li>\r\n                <div class="pic">\r\n                    <img src="' +
((__t = ( require('./images/foot-icon1.png') )) == null ? '' : __t) +
'" alt="">\r\n                </div>\r\n                <p>中国·上海  上海卓尤化工科技有限公司</p>\r\n                <span>\r\n                    <img src="' +
((__t = ( require('./images/foot-icon4.png') )) == null ? '' : __t) +
'" alt="">\r\n                    上海市嘉定江桥镇金运路852号5栋\r\n                </span>\r\n                <span>\r\n                    <img src="' +
((__t = ( require('./images/foot-icon5.png') )) == null ? '' : __t) +
'" alt="">\r\n                    021 - 52842801  /   36336515   /   66081170\r\n                </span>\r\n            </li>\r\n            <div class="line"></div>\r\n            <li>\r\n                <div class="pic">\r\n                    <img src="' +
((__t = ( require('./images/foot-icon2.png') )) == null ? '' : __t) +
'" alt="">\r\n                </div>\r\n                <p>中国·青岛  青岛卓尤新材料有限公司</p>\r\n                <span>\r\n                    <img src="' +
((__t = ( require('./images/foot-icon4.png') )) == null ? '' : __t) +
'" alt="">\r\n                    青岛市高新区春阳路820号\r\n                </span>\r\n                <span>\r\n                    <img src="' +
((__t = ( require('./images/foot-icon5.png') )) == null ? '' : __t) +
'" alt="">\r\n                    0532 - 58717889  /   58717881\r\n                </span>\r\n            </li>\r\n            <div class="line"></div>\r\n            <li>\r\n                <div class="pic">\r\n                    <img src="' +
((__t = ( require('./images/foot-icon3.png') )) == null ? '' : __t) +
'" alt="">\r\n                </div>\r\n                <p>中国·深圳  深圳市卓尤新材料有限公司</p>\r\n                <span>\r\n                    <img src="' +
((__t = ( require('./images/foot-icon4.png') )) == null ? '' : __t) +
'" alt="">\r\n                    深圳市龙华区龙华大道864号明珠商务大厦9C\r\n                </span>\r\n                <span>\r\n                    <img src="' +
((__t = ( require('./images/foot-icon5.png') )) == null ? '' : __t) +
'" alt="">\r\n                    0755 - 82788517  /   82788197\r\n                </span>\r\n            </li>\r\n            <div class="line"></div>\r\n        </ul>\r\n    </div>\r\n    <div class="bottom">\r\n        <div class="footer-logo">\r\n            <span></span>\r\n            <img src="' +
((__t = ( require('./images/foot-logo.png') )) == null ? '' : __t) +
'" alt="">\r\n            <span></span>\r\n        </div>\r\n        <div class="container">\r\n            <div class="copy">© 2023 chooyuchem.com - all rights reserved.</div>  \r\n            <div class="right">\r\n                <a href="https://beian.miit.gov.cn/">鲁ICP备09009989号-1</a>\r\n                <a href="https://dongbaqu.com/">网站建设 | 东八区网络</a>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</footer>';

}
return __p
}