import './index.scss'
$(".hamburger").click(function(){
    $(this).toggleClass("active");
    $(".mob-nav").toggleClass("active");
    $(".open-menu").toggleClass("active");
    $(".function .btn").css("display", "flex");
    $(".function .search").hide()
})
$(".mob-nav .nLi").click(function() {
    $(this).find(".sub").slideToggle();
    $(this).toggleClass("active");
})
// $(".search").click(function() {
//     $(".search-xl").slideToggle();
// })
